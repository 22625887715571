import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn,postLogin, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { getActivePeriod } from 'services/UtilsService'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try { 
            
            const resp = await postLogin(values)
            //resp.data.authority=['admin', 'user'];
            console.log("logg",resp)
            resp.data.avatar='';
            if (resp.data) {
                const { token } = resp.data;
                //console.log("logg",resp.data)
          
                //Devolvemos periodo
                const responsePeriod = await getActivePeriod(resp.data.token)

 

                if (resp.data && responsePeriod.data) {
                    //Verificamos que roles tiene
                    if(resp.data.roles){
                        const roles = resp.data.roles;
                        const authorityArray = roles.map(rol => {
                            return rol.name
                        })
                        dispatch(onSignInSuccess(resp.data.token))
                        console.log("signed")
                        
                        dispatch(
                            setUser(
                                 {
                                    avatar: '',
                                    userName: resp.data.userName,
                                    authority: resp.data.userName=="Administrator" ? ['admin', 'user'] : authorityArray,
                                    email: resp.data.email,
                                    phone:resp.data.phone,
                                    id:resp.data.id,
                                    permissions: resp.data.permissions,
                                    userFields:resp.data.userFields,
                                    roles:resp.data.roles,
                                    token: resp.data.token,
                                    period: responsePeriod.data ? responsePeriod.data[0] : null
                                }
                            )
                        )


                        const redirectUrl = query.get(REDIRECT_URL_KEY)
                        console.log("redirecturl", redirectUrl)
                        navigate(appConfig.authenticatedEntryPath )
                            //redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                       
                        console.log("yes")
                        console.log("redirecturl", redirectUrl)
        
                        return {
                            status: 'success',
                            message: '',
                        }
                    }else{
                        return {
                            status: 'failed',
                            message: "Error sign in. No Roles configurated",
                        }
                    }
                }
                else{
                    return {
                        status: 'failed',
                        message: "Error sign in. No authorization for Period Endpoint",
                    }
                }
               
            }else{
                return {
                    status: 'failed',
                    message: "Error sign in",
                }
            }
        } catch (errors) {
            console.log("error")
            console.log("error", errors)
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }


    const getToken = () =>{
        const { token } = useSelector((state) => state.auth.session)
        return token;
    }

    // const signUp = async (values) => {
    //     try {
    //         const resp = await apiSignUp(values)
    //         if (resp.data) {
    //             const { token } = resp.data
    //             dispatch(onSignInSuccess(token))
    //             if (resp.data.user) {
    //                 dispatch(
    //                     setUser(
    //                         resp.data.user || {
    //                             avatar: '',
    //                             userName: 'Anonymous',
    //                             authority: ['USER'],
    //                             email: '',
    //                         }
    //                     )
    //                 )
    //             }
    //             const redirectUrl = query.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
    //             )
    //             return {
    //                 status: 'success',
    //                 message: '',
    //             }
    //         }
    //     } catch (errors) {
    //         return {
    //             status: 'failed',
    //             message: errors?.response?.data?.message || errors.toString(),
    //         }
    //     }
    // }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
       // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        //signUp,
        signOut,
        getToken
    }
}

export default useAuth
