import appConfig from 'configs/app.config'  
import refreshTokenConfig from 'configs/token.config'  
const { apiPrefix, urlGateway } = appConfig
import { onSignInSuccess } from 'store/auth/sessionSlice'
import { useDispatch } from 'react-redux'



 function Something(tokenfinal) {                                         
  const dispatch = useDispatch()
  //dispatch(onSignInSuccess(tokenfinal))
  
 return true;
}  

async function callRefreshToken(token) {
    const resultToken = await refreshTokenConfig(token);
    let data = await JSON.parse(localStorage.getItem('admin'))
    let authdata=JSON.parse(data.auth);
    authdata.session.token=resultToken.data;
    authdata.user.token=resultToken.data;
    data.auth=JSON.stringify(authdata);
    const arrayString = data;

    console.log("GENERADO")
    localStorage.setItem('admin', JSON.stringify(arrayString));

    return resultToken.data;
    //Something(resultToken.data)
    //return true;
  }

export async function getActivePeriod(token) {
    var url = new URL(`${urlGateway}${apiPrefix}/Utils/Periods`);
    url.searchParams.append('Filter','x.offSetPeriod=0' );
    url.searchParams.append('pageSize', 1);


    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };

    let confirm = 0;

    //do {
    return  fetch(url, params)
        .then(async response => {
            console.log("TOKEN INICIAL", response)

            if(response.status===401 && !response.ok){
                console.log("NO AUTORIZADO, EVALUAR TOKEN", response)
                // //const resultRefreh= callRefreshToken(token)
                //  await callRefreshToken(token).then(function(result){
                //     console.log(result)
                //     console.log("TERMINO EVALUAR, NUEVO TOKEN")
                //     return getActivePeriod(result)
                //     })
                return null;
               }

            console.log("AUTORIZADO, PROSEGUIMOS Y SALIMOS DE DO WHILE")
            //confirm=1;
            return response.json();
        })
        .catch(err => {
            return err.message;
        });
    //}while (confirm<1)
}

export function getReturnReasons(token) {
    var url = new URL(`${urlGateway}${apiPrefix}/Utils/ReturnReasons`);
    url.searchParams.append('Filter','x.active="Y"' );

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getSalesReps(token) {
    var url = new URL(`${urlGateway}${apiPrefix}/security/Users`);
    url.searchParams.append('Filter','x.UserFields.any(r=>r.fieldsId=1)' );

    url.searchParams.append('pageNumber', 1);

   

    
    url.searchParams.append('pageSize', 100);
    url.searchParams.append('includeUserFields',true);

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getSalesRepresentatives(data) {
    var url = new URL(`${urlGateway}${apiPrefix}/security/Users`);


    if(data.Filter!=""){
        url.searchParams.append('Filter', data.Filter);
    }

        url.searchParams.append('pageNumber', 1);

   

    
    url.searchParams.append('pageSize', 100);

    url.searchParams.append('includeUserFields',true);

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}



export function getDocumentPDF(data){
    console.log("DATA ENVIADA", JSON.stringify(data.subdata))
    var url = new URL(`${urlGateway}${apiPrefix}/Layouts/Layouts/document`);
    const params={
        method:"POST",
        body:JSON.stringify(data.subdata),
        headers:{
            "Content-Type":"application/json",
            "Authorization": "Bearer "  + data.token

        },
        //responseType: "arraybuffer"
    };
    return fetch(url,params)
    .then( response=>{

        return response.blob();
    })
    .catch(err=>{
        return err.message;
    });
}