const appConfig = {
    //routes
    apiPrefix: '/gateway',
    urlGateway: 'https://gateway.ethnixgroup.com:65315', //'https://testgateway.ethnixgroup.com:65319',//'http://50.254.255.28:65319',//'http://192.168.1.14:9000', http://65.254.210.213:65315
    //default configuration 
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true,
    //authenticatedEntryPath: '/app/sales/dashboard',
    authenticatedEntryPath: '/pages/welcome',
    locale: 'en',
    
    //Azure images
    AZURE_STORAGE_CONNECTION_STRING: "",
    AZURE_CONTAINER_NAME:"",
    AZURE_STORAGE_ACCOUNT_NAME: "",
    sasToken: "",
}

export default appConfig
