import appConfig from 'configs/app.config'  
const { apiPrefix, urlGateway } = appConfig

export async function refreshTokenConfig (token){
    var url = new URL(`${urlGateway}${apiPrefix}/Security/Login/RefreshToken`);

    const params={
        method:"POST",
        headers:{
            "Authorization": "Bearer " + token
        }
    };
    return await fetch(url,params)
    .then(response=>{
        console.log("RESPONSE TOKEN", response)
        return response.json();
    })
    .then(result=>{
        return result;
    })
    .catch(err=>{
        return err.message;
    });
}

export default refreshTokenConfig
