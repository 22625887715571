import ApiService from './ApiService'  //borrar
import appConfig from 'configs/app.config'  
const { apiPrefix, urlGateway } = appConfig


export function postLogin(data) {
    data.includePermissions=true;
    data.includeRoles=true;
    data.includeUserFields=true;

    console.log("imprimiendo data",data)

    var url = new URL(`${urlGateway}${apiPrefix}/Security/Login`);

    const params={
        method:"POST",
        body:JSON.stringify(data),
        headers:{
            "Content-Type":"application/json"
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export  function getUsers(data) {

    var url = new URL(`${urlGateway}${apiPrefix}/Security/Users`);

    if(data.Filter!=""){
        url.searchParams.append('Filter', data.Filter);
    }

    console.log("imprimiendo url", url.toString())
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "  + data.token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {

            return result;
        })
        .catch(err => {
            return err.message;
        });
}


//////////////////////////////////////////////
export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
