export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Dany Cabanzo',
        email: 'd.cabanzo@limenainc.net',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'd.cabanzo@limenainc.net',
        id_SAP: 3,
        //tokenBW: '3enQLU1xWhujTjALYCaSU6jFd5S0dPgjiiKzcPY7SVRfi8fZLIFVrOnDWrHsKHGs+cNySJMiliq2aXCAVcdq7TPxmZ+hlCMBs8fmE3n5I17FBGLpyycFxvvIMBWp4pi3Zfy+KJIBbWCNcJTwU1BaEhAPvM2qiF/JvDelx5I4G5z5q4m4gttFg/knWkzV6pLynujTDahthEmbAg/glyuNiOWX5jYeFpc/bnOzPICTCh/rEizZhqCqsiYiHJxD/zH+8prQjmGx1Z7zm/mV6dOkyDiKyIt8DoXlHiYr9lDnvaq0bB2KFoza1jc89u3kmNr8rhLre7/ECkYeMTt5qOQLf24kFpjcWkWP9P1zKPTjx1EUJPh+NbCDrrnXau+C6T/1vARMhl8dsFuCZ53lgaxWJntKLu85dBEQ6Jr5SEY/QW69tkRSZcncu9x9H9VHK67z'
    },
]
